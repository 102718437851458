<template>
    <header
        ref="header"
        class="header-nav absolute w-full max-w-page transition-colors duration-150"
    >
        <div ref="dimmer" class="header-nav__dimmer overlay--grey-11-50 fixed inset-0 z-10 hidden opacity-0 cursor-pointer" @click="menuState = false"></div>
        <div ref="menuBg" class="header-nav__menu-bg absolute inset-0 w-full h-full bg-white opacity-0 z-10"></div>
        <div class="header-nav__container bg-brand h-full">
            <div ref="menuContainer" class="grid-container relative h-full z-20 max-w-page">
                <div class="grid-row h-full">
                    <div class="grid-col w-full flex justify-between xl:justify-start items-center">
                        <button class="header-nav__burger-menu xl:hidden" @click="menuState = !menuState">
                            <div :class="{'transform rotate-41 bg-navy-blue': menuState, 'bg-white': !menuState}" class="transition-transform duration-300"></div>
                            <div :class="{'opacity-0': menuState, 'bg-white': !menuState}" class="transition-opacity duration-300"></div>
                            <div :class="{'transform -rotate-41 bg-navy-blue': menuState, 'bg-white': !menuState}" class="transition-transform duration-300"></div>
                        </button>

                        <div class="header-nav__logo-container">
                            <slot name="logo"></slot>
                        </div>

                        <div
                            ref="menu"
                            class="header-nav__menu overflow-y-scroll xl:overflow-visible hidden xl:block"
                        >
                            <div ref="menuContent" class="header-nav__menu-inner xl:w-full xl:flex xl:items-center xl:justify-between px-6 py-10 xl:px-0 xl:py-0">
                                <div class="flex flex-col xl:flex-row xl:items-center">
                                    <nav class="header-nav__left-menu flex flex-col xl:flex-row xl:items-center">
                                        <div
                                            v-for="(item, index, key) in leftMenu"
                                            :key="`level-0-${key}`"
                                        >
                                            <a
                                                v-if="item.type === 'link'"
                                                :href="item.link.url"
                                                :target="item.link.target"
                                                :class="{'mt-6 xl:mt-0 xl:ml-1' : index > 0, 'current': item.link.url === currentUrl }"
                                                class="header-nav__nav-item header-nav__nav-item--main inline-flex text-nav-main xl:px-3.5 xl:py-2 xl:rounded-3xl"
                                            >{{ item.link.label }}</a>

                                            <HeaderSubmenu
                                                v-if="item.type === 'submenu'"
                                                :submenu="item"
                                                :class="{'mt-6 xl:mt-0 xl:ml-1' : index > 0}"
                                                @menu-state-change="updateSubmenuState($event)"
                                            ></HeaderSubmenu>
                                        </div>
                                    </nav>
                                    <nav class="header-nav__right-menu flex flex-col xl:flex-row xl:items-center">
                                        <div
                                            v-for="(item, index, key) in rightMenu"
                                            :key="`level-0-${key}`"
                                        >
                                            <a
                                                v-if="item.type === 'link'"
                                                :href="item.link.url"
                                                :target="item.link.target"
                                                :class="{'mt-8 xl:mt-0 xl:ml-8' : index > 0, 'current': item.link.url === currentUrl}"
                                                class="header-nav__nav-item header-nav__nav-item--secondary inline-flex text-nav-secondary"
                                            >{{ item.link.label }}</a>

                                            <HeaderSubmenu
                                                v-if="item.type === 'submenu'"
                                                :submenu="item"
                                                main-link-style="secondary"
                                                :class="{'mt-8 xl:mt-0 xl:ml-8' : index > 0}"
                                                @menu-state-change="updateSubmenuState($event)"
                                            ></HeaderSubmenu>
                                        </div>
                                    </nav>
                                </div>
                                <div class="header-nav__right-part mt-6 lg:mt-0">
                                    <a
                                        v-if="rightLink && rightLink.url"
                                        :href="rightLink.url"
                                        :target="rightLink.target"
                                        class="text-nav-main text-navy-blue lg:text-white special-underline special-underline--straight special-underline--white-blue after:-bottom-1.5"
                                    >
                                        {{ rightLink.label }}
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div></div>
                    </div>
                </div>
            </div>
        </div>
    </header>
</template>

<script>
    import { gsap } from '@lahautesociete/gsap';
    import { ScrollToPlugin } from '@lahautesociete/gsap/ScrollToPlugin';
    import HeaderSubmenu from "@/app/components/HeaderSubmenu.vue";

    gsap.registerPlugin(ScrollToPlugin);

    export default {
        name: "HeaderNav",

        components: { HeaderSubmenu },

        props: {
            leftMenu: { type: Object, required: true },
            rightMenu: { type: Object, required: true },
            rightLink: { type: Object, required: true },
            currentUrl: { type: String, default: '/'}
        },

        data: () => ({
            menuState: false,
            submenuState: false,
            currentSubmenu: null,
            breakpoint: 1200
        }),

        watch: {
            menuState() {
                if (this.menuState) {
                    document.querySelector('.header-nav').classList.add('opened');
                } else {
                    document.querySelector('.header-nav').classList.remove('opened');
                }

                // Show/hide the dimmer
                gsap.to(this.$refs.dimmer, {
                    display: (this.menuState || this.submenuState) ? 'block' : 'none',
                    opacity: (this.menuState || this.submenuState) ? 1 : 0,
                    duration: 0.4,
                    ease: 'power2.out'
                });

                // on mobile
                if (window.innerWidth < this.breakpoint) {
                    // Show/hide mobile menu
                    gsap.fromTo(this.$refs.menu, {
                        display: (this.menuState) ? 'none' : 'block',
                        x: (this.menuState) ? '-100%' : '0%'
                    }, {
                        display: (this.menuState) ? 'block' : 'none',
                        x: (this.menuState) ? '0%' : '-100%',
                        duration: 0.35,
                        ease: 'power2.out',
                        onComplete: () => {
                            // Reset submenu/menu placement
                            gsap.set(this.$refs.menuContent, {
                                x:'0%'
                            });

                            // Hide submenus
                            gsap.set(document.querySelectorAll('.header-submenu__content'), {
                                display: 'none'
                            });
                        }
                    });

                    // Show/hide the background of the menu
                    gsap.to(this.$refs.menuBg, {
                        opacity: (this.menuState) ? 1 : 0,
                        delay: (this.menuState) ? 0 : 0.5,
                    });

                    // Prevent from scrolling
                    gsap.set('html, body', {
                        overflow: (this.menuState) ? 'hidden' : 'visible'
                    });

                    // Scroll to top
                    if (this.menuState) {
                        gsap.to(window, {
                            scrollTo: 0,
                            duration: 0.2
                        });
                    }
                }
            },

            submenuState() {
                if (this.submenuState) {
                    document.querySelector('.header-nav').classList.add('opened');
                } else {
                    document.querySelector('.header-nav').classList.remove('opened');
                }
                // on mobile
                if (window.innerWidth < this.breakpoint) {
                    // Move content to left/right to show submenu/menu
                    gsap.fromTo(this.$refs.menuContent, {
                        x: (this.submenuState) ? '0%' : '-100%'
                    }, {
                        x: (this.submenuState) ? '-100%' : '0%',
                        duration: 0.35,
                        ease: 'power2.out'
                    });
                } else {
                    // Show/hide the background of the menu
                    gsap.to(this.$refs.menuBg, {
                        opacity: (this.submenuState) ? 1 : 0,
                        delay: (this.submenuState) ? 0 : 0.5,
                    });
                }

                // Show/hide the dimmer
                gsap.to(this.$refs.dimmer, {
                    display: (this.menuState || this.submenuState) ? 'block' : 'none',
                    opacity: (this.menuState || this.submenuState) ? 1 : 0,
                    duration: 0.4,
                    delay: 0.4,
                    ease: 'power2.out'
                });

            }
        },

        methods: {
            updateSubmenuState(state) {
                this.submenuState = state;
            }
        }
    }
</script>

<style lang="scss">
    .header-nav {
        @apply h-14;

        @screen xl {
            height: 4.5rem;
        }

        &__logo-container {
            @apply h-10;

            @screen xl {
                height: 3.125rem;
            }
        }

        &__menu {
            @apply top-14 left-0 flex-col fixed h-screen bg-white w-full;
            max-width: 400px;

            @screen xl {
                @apply top-auto left-auto static h-auto flex flex-row justify-between items-center flex-grow ml-16 w-auto max-w-none bg-transparent;
            }
        }

        &__right-menu {
            &:before {
                content: '';
                @apply block w-full h-px my-6 bg-white opacity-40;

                @screen xl {
                    @apply block w-px mx-8;
                    height: 1.8125rem;
                }
            }
        }

        &__right-part {
            &:before {
                content: '';
                @apply block w-full h-px my-6 bg-navy-blue opacity-40;

                @screen xl {
                    @apply hidden;
                }
            }
        }

        &__burger-menu {
            > div {
                @apply block h-0.5 rounded-2xl;
                width: 1.125rem;
                transform-origin: center left;
            }

            div + div{
                margin-top: 4px;
            }
        }

        &__nav-item {
            @apply inline-flex justify-between relative w-full transition-colors duration-300;

            @screen xl {
                @apply w-auto outline-none;
            }

            &:after {
                content: '';
                background-image: url('@/static/images/icons/chevron-right-navy-blue.svg');
                @apply block w-6 h-6 ml-6;

                @screen xl {
                    @apply hidden;
                }
            }

            &--main {
                @apply text-navy-blue;

                @screen xl {
                    @apply text-white;

                    &.current, &:hover, &:focus {
                        @apply bg-white text-navy-blue;
                    }

                    &:focus {
                        @apply bg-beige;
                    }
                }
            }

            &--secondary {
                @apply relative text-navy-blue;

                @screen xl {
                    @apply text-white;

                    &:after {
                        content: '';
                        @apply block w-0 h-px bg-white absolute -bottom-0.5 left-0 bg-none transition-all duration-200 ml-0 ;
                    }

                    &.current, &:hover, &:focus {
                        &:after {
                            @apply w-full;
                        }
                    }
                }
            }
        }

        &.opened {
            .header-nav__logo--dark {
                opacity: 0;
            }

            .header-nav__logo--light {
                opacity: 1;
            }

            .header-nav__nav-item--main,
            .header-nav__nav-item--secondary {
                @screen xl {
                    @apply text-navy-blue;
                }
            }
        }
    }
</style>
