<template>
    <div class="scroll-slider relative bg-white overflow-hidden" :style="componentHeight">
        <div :class="`bg-${color}`" class="scroll-slider__bg-mobile" />
        <div ref="scroller" class="scroll-slider__scroller md:h-screen relative">
            <div class="scroll-slider__container grid-container h-full md:py-20 relative">
                <div class="scroll-slider__row grid-row md:flex-nowrap h-full">
                    <div class="scroll-slider__col grid-col hidden md:h-full md:flex md:justify-start md:items-center">
                        <div class="h-full w-auto relative md:flex md:justify-start md:items-center">
                            <div :class="`bg-${color}`" class="scroll-slider__bg" />
                            <div class="scroll-slider__image-container w-auto h-4/5 relative flex items-center">
                                <div
                                    v-for="(slide, index, key) in slides"
                                    ref="image"
                                    :key="`slide-image-${key}`"
                                    :class="{'absolute left-0 top-1/2 transform -translate-y-1/2 opacity-0': index > 0}"
                                    class="rounded-lg md:rounded-2xl overflow-hidden max-h-full aspect-4/5"
                                >
                                    <img
                                        v-if="slide.image && slide.image.url"
                                        :src="slide.image.url"
                                        :alt="slide.image.alt"
                                        class="h-full"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="grid-col relative w-full md:h-full md:w-8/12 lg:w-6/12 py-10 md:py-0">
                        <div class="scroll-slider__text-container relative md:h-full md:flex md:flex-col md:items-start md:justify-center">
                            <slot name="heading"></slot>
                            <div class="scroll-slider__text-content text-navy-blue mt-6 md:mt-10">
                                <div
                                    v-for="(slide, index, key) in slides"
                                    ref="block"
                                    :key="`slide-text-${key}`"
                                    :class="{ 'mt-6 md:mt-8': index > 0 }"
                                >
                                    <div
                                        :class="{'current': currentIndex === index}"
                                        class="scroll-slider__heading-container"
                                        @click="updateIndex(index)"
                                    >
                                        <h3
                                            :class="{'opacity-30': currentIndex !== index}"
                                            class="cursor-pointer md:cursor-auto"
                                        >
                                            <strong>{{ slide.heading }}</strong>
                                        </h3>
                                    </div>
                                    <div
                                        ref="textContainer"
                                        :class="{ 'h-0': index > 0}"
                                        class="scroll-slider__text overflow-hidden"
                                    >
                                        <div ref="textInner" class="scroll-slider__text-inner">
                                            <div class="body-1 pt-2" v-html="slide.text"></div>
                                            <div class="md:hidden rounded-lg md:rounded-2xl overflow-hidden mt-6">
                                                <img
                                                    :src="slide.image.url"
                                                    :alt="slide.image.alt"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <a
                                v-if="cta && cta.url && cta.label"
                                :href="cta.url"
                                :target="cta.target"
                                class="btn btn--M btn--ghost-blue mt-10"
                            >{{ cta.label }}</a>
                        </div>
                    </div>
                </div>
            </div>
            <div
                v-if="slides.length > 1"
                class="scroll-slider__dots-container hidden md:block absolute top-1/2 md:right-6 xl:right-14 transform -translate-y-1/2"
            >
                <div
                    v-for="(slide, index, key) in slides"
                    :key="`slide-dot-${key}`"
                    :class="{
                        'md:mt-10': index > 0,
                        'bg-navy-blue': currentIndex === index,
                        'bg-grey-80': currentIndex !== index
                    }"
                    class="w-2 h-2 rounded-full transition-colors duration-300"
                ></div>
            </div>
        </div>
    </div>
</template>

<script>
    import imagesLoaded from "imagesloaded";
    import { gsap } from '@lahautesociete/gsap';
    import { ScrollToPlugin } from '@lahautesociete/gsap/ScrollToPlugin';
    import { ScrollTrigger } from '@lahautesociete/gsap/ScrollTrigger';

    gsap.registerPlugin(ScrollTrigger);
    gsap.registerPlugin(ScrollToPlugin);

    export default {
        name: 'ScrollSlider',

        props: {
            color: { type: String, default: 'blue' },
            slides: { type: Array, default: () => [] },
            cta: { type: Object, default: () => {} }
        },

        data: () => ({
            st: null,
            breakpoint: 768,
            progress: 0,
            currentIndex: 0,
            slidePercent: 0,
            componentHeight: '',
            device: '',
            windowHeightRatio: 80
        }),

        watch: {
            progress() {
                // Get the current index according to the progress
                // Min 0 - Max slides length
                this.currentIndex = Math.min(this.slides.length - 1, Math.max(0, Math.floor(this.progress / this.slidePercent)));

                this.resizeBg();
            },

            currentIndex() {
                // Hide all images
                gsap.to(this.$refs.image, {
                    opacity: 0,
                    duration: 0.7,
                    ease: 'power2.out'
                });

                // Show right image
                gsap.to(this.$refs.image[this.currentIndex], {
                    opacity: 1,
                    duration: 0.7,
                    ease: 'power2.out'
                });

                // Reduce all text containers
                gsap.to(this.$refs.textContainer, {
                    height: 0,
                    duration: 0.3,
                    ease: 'power2.out'
                });

                // Show right text container
                gsap.to(this.$refs.textContainer[this.currentIndex], {
                    height: this.$refs.textInner[this.currentIndex].clientHeight,
                    duration: 0.3,
                    ease: 'power2.out',
                    onComplete: () => {
                        if (this.device === 'mobile') {
                            gsap.to(window, {
                                scrollTo: this.$refs.block[this.currentIndex].getBoundingClientRect().top + window.scrollY,
                                duration: 0.2,
                                ease: 'power2.out'
                            })
                        }
                    }
                });

            },

            device() {
                if (this.device === 'mobile') {
                    if (this.st) this.st.kill(); this.st = null;
                    this.componentHeight = 'height: auto';
                } else {
                    if (!this.st) this.initScrolltrigger();
                    this.componentHeight = `height: ${(this.slides.length + 1) * this.windowHeightRatio}vh`;
                }
            }
        },

        mounted() {
            if (this.slides.length > 1) {
                // Get percentage of progress of one slide
                this.slidePercent =  1 / this.slides.length;

                window.addEventListener('resize', this.resizeHandler);

                this.resizeHandler();

            }
            imagesLoaded('.scroll-slider__image-container', () => {
                this.resizeBg();
            });
        },

        methods: {
            // Init the scrolltrigger to pin the container
            initScrolltrigger() {
                if (this.st) this.st.kill;

                this.st = ScrollTrigger.create({
                    trigger: this.$refs.scroller,
                    start: 'top top',
                    end: (this.slides.length + 1) * (window.innerHeight * this.windowHeightRatio / 100) + ' bottom',
                    pin: true,
                    onUpdate: self => this.progress = self.progress
                });
            },

            updateIndex(index) {
                if (window.innerWidth < this.breakpoint) {
                    this.currentIndex = index;
                }
            },

            resizeBg() {
                if (window.innerWidth > 1920) {
                    gsap.to(document.querySelector('.scroll-slider__bg'), {
                        width: 1920 - 3 * this.$refs.image[0].clientWidth / 4 - 80
                    });
                }
            },

            // On resize
            resizeHandler() {
                if (window.innerWidth < this.breakpoint) {
                    this.device = 'mobile';
                } else {
                    this.device = 'desktop';
                }

                this.resizeBg();

                this.$nextTick(() => {
                    // Readapt height of current text container shown
                    gsap.set(this.$refs.textContainer[this.currentIndex], {
                        height: this.$refs.textInner[this.currentIndex].clientHeight
                    });
                });
            }
        }
    }
</script>

<style lang="scss">
    .scroll-slider {
        @media (min-width: 768px) and (max-height: 850px) {
            &__container {
                @apply py-10;
            }
        }

        @media (orientation: portrait) and (min-width: 768px) {
            &__row {
                @apply items-center;
            }

            &__col {
                @apply h-1/2;
            }
        }

        &__bg {
            @apply w-full h-full absolute top-0 bottom-0 left-0 right-auto;
            width: 100vw;
            left: 76%;

            @screen md {
                height: 70vh;
                @apply top-1/2 transform -translate-y-1/2;
            }

            @screen lg {
                @apply h-full;
            }
        }

        &__bg-mobile {
            @apply w-full h-full absolute top-0 bottom-0 left-0 right-0;

            @screen md {
                @apply hidden;
            }
        }

        &__text-container {
            @screen md {
                margin-left: 12%;
                max-width: 40.625rem;
            }
        }

        &__heading-container {
            @apply inline-flex w-full justify-between items-center;

            &:after {
                content: '';
                @apply block w-6 h-6 transition-opacity duration-300 flex-shrink-0 ml-6;
                background-image: url('@/static/images/icons/plus.svg');

                @screen md {
                    @apply hidden;
                }
            }

            &.current:after {
                @apply opacity-0;
            }
        }
    }

    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.5s ease;
    }

    .fade-enter-from,
    .fade-leave-to {
        opacity: 0;
    }
</style>
