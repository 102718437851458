<template>
    <div class="product-list-component flex flex-row flex-wrap justify-start -mt-6 xl:-mt-10 w-full">
        <ProductCard
            v-for="(product, index) in products"
            :key="`product-card-${index}`"
            :product="product"
            :class="[{
                'w-full md:w-1/2 lg:w-1/2 xxxl:w-1/3': filterState,
                'w-full md:w-1/2 lg:w-1/3 xxxl:w-1/4': !filterState
            }]"
            class="grid-col mt-6 xl:mt-10"
            :style="`order: ${ (index <= 3) ? index + 1: index + 2}`"
        ></ProductCard>

        <div
            :class="{
                'w-full xxxl:w-2/3': filterState,
                'w-full lg:w-2/3 xxxl:w-1/2': !filterState
            }"
            class="product-list-component__featured-link grid-col order-5 mt-6 xl:mt-10"
        >
            <slot name="featured"></slot>
        </div>
    </div>
</template>

<script>
    import ProductCard from '@/app/components/ProductCard.vue';
    export default {
        name: 'ProductList',

        components: { ProductCard },

        props: {
            products: { type: Array, default: () => [], required: true },
            filterState: { type: Boolean, default: false }
        }
    }
</script>

<style lang="scss">
    .product-list-component {
        &__featured-link {
            > * {
                @apply h-full;
            }
        }
    }
</style>
