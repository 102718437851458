<template>
    <div class="hero-product-image-slider overflow-hidden rounded-2xl relative">
        <ul
            ref="imgContainer"
            class="hero-product-image-slider__content w-full h-full flex flew-row items-center flex-nowrap relative"
            v-touch:swipe="swipeHandler"
        >
            <li
                v-for="(image, index, key) in images"
                :key="key"
                ref="imgContent"
                :class="{'opacity-0': index > 0}"
                class="w-full h-full flex-shrink-0 absolute inset-0"
            >
                <img
                    ref="img"
                    :src="image.image.url"
                    :class="{'cursor-zoom-out': zoomMode, 'cursor-zoom-in': !zoomMode }"
                    :alt="image.image.alt"
                    class="object-cover w-full h-full"
                    @click="zoomMode = !zoomMode"
                />
            </li>
        </ul>

        <div class="hero-product-image-slider__buttons absolute bottom-2 md:bottom-6 left-1/2 transform -translate-x-1/2 flex items-center">
            <button
                class="btn-arrow btn-arrow--left btn-arrow--light btn-arrow--L cursor-pointer w-8 h-8"
                @click="updateCurrentIndex(currentImgIndex - 1)"
            ></button>
            <button
                class="btn-arrow btn-arrow--right btn-arrow--light btn-arrow--L ml-6 cursor-pointer w-8 h-8"
                @click="updateCurrentIndex(currentImgIndex + 1)"
            ></button>
        </div>
    </div>
</template>

<script>
    import { gsap } from '@lahautesociete/gsap';

    export default {
        name: 'HeroProductImageSlider',

        props: {
            type: { type: String, default: '', required: true },
            images: { type: Array, default: () => [] },
        },

        data: () => ({
            zoomMode: false,
            zoomRatio: 1.5,
            currentImgIndex: 0
        }),

        computed: {
            currentImageType() {
                const currentImage = this.images[this.currentImgIndex];

                return currentImage.type;
            },
        },

        watch: {
            zoomMode() {
                // Set the ratio according to the zoom mode
                gsap.set(this.$refs.img[this.currentImgIndex], {
                    scale: (this.zoomMode) ? this.zoomRatio : 1
                });

                // If on zoom mode
                if (this.zoomMode) {
                    // Add the listner to track the mouse
                    this.$refs.img[this.currentImgIndex].addEventListener('mousemove', this.trackMouse);
                } else {
                    this.resetTracking();
                }
            },

            currentImgIndex() {
                if (this.currentImgIndex < 0) this.currentImgIndex = this.images.length - 1;
                if (this.currentImgIndex > this.images.length - 1) this.currentImgIndex = 0;

                this.updateCurrentSlide();
            }
        },

        methods: {
            trackMouse(pEvent) {
                // Get mouse position on img, container's height, and percentage
                const mousePos = pEvent.layerY;
                const containerHeight = this.$refs.imgContainer.clientHeight;
                const posPer = (mousePos / containerHeight);

                // Replace image
                gsap.set(this.$refs.img[this.currentImgIndex], {
                    y: - ((this.$refs.img[this.currentImgIndex].clientHeight * this.zoomRatio - containerHeight) * (posPer - 0.5))
                });
            },

            resetTracking() {
                // Reset img place
                gsap.set(this.$refs.img[this.currentImgIndex], {
                    y: 0,
                    scale: 1
                });

                // Remove the listener
                this.$refs.img[this.currentImgIndex].removeEventListener('mousemove', this.trackMouse);
            },

            updateCurrentIndex(newIndex) {
                this.resetTracking();

                this.currentImgIndex = newIndex;
                let duration = 0.5;

                if (this.currentImgIndex < 0) {
                    this.currentImgIndex = this.images.length - 1;
                    duration = 0.05;
                }
                if (this.currentImgIndex > this.images.length - 1)  {
                    this.currentImgIndex = 0;
                    duration = 0.05;
                }


                this.updateCurrentSlide(duration);
            },

            updateCurrentSlide() {
                gsap.to(this.$refs.imgContent, {
                    opacity: 0,
                    duration: 0.6,
                    ease: 'power2.out'
                });

                gsap.to(this.$refs.imgContent[this.currentImgIndex], {
                    opacity: 1,
                    duration: 0.6,
                    ease: 'power2.out'
                });
            },

            swipeHandler(direction) {
                this.currentImgIndex = (direction === 'left') ? this.currentImgIndex + 1 : this.currentImgIndex - 1;
            }
        }
    }
</script>

<style lang="scss">
    .hero-product-image-slider {
        height: 23.5rem;

        @screen md {
            height: 32rem;
        }

        @screen lg {
            @apply h-full;
        }

        @media (min-width: 1440px) {
            @apply h-full;
            min-height: 683px;
            max-height: calc(100vh - 6.5625rem - 5rem);
        }

        @media (max-height: 800px) and (min-width: 1440px) {
            min-height: auto;
        }

        @media (min-width: 1600px) {
            @apply w-auto;
        }
    }
</style>
