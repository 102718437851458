<template>
    <div ref="carousel" class="carousel">
        <div
            ref="container"
            :class="{'xl:flex xl:justify-center': desktopCenter && carousel && slidesWidth < carouselWidth}"
            class="carousel__content keen-slider"
            :style="centerAdjust"
        >
            <slot></slot>
        </div>

        <div
            v-if="carousel && carousel.track.details && desktopArrows"
            :class="{'hidden md:block': desktopArrows}"
            class="carousel__arrow-container absolute top-1/2 transform -translate-y-1/2 w-full"
        >
            <button
                v-show="currentIndex > 0 && carousel.track.details.min <= carousel.track.details.position"
                :class="[`btn-arrow--${arrowsSize} btn-arrow--${arrowsStyle}`]"
                class="carousel__arrow-left btn-arrow btn-arrow--left transform -translate-y-1/2"
                @click="carousel.prev()"
            />
            <button
                v-show="currentIndex < carousel.track.details.maxIdx && slidesWidth >= carouselWidth"
                :class="[`btn-arrow--${arrowsSize} btn-arrow--${arrowsStyle}`]"
                class="carousel__arrow-right btn-arrow btn-arrow--right transform -translate-y-1/2"
                @click="carousel.next()"
            />
        </div>
        <div
            v-if="mobileProgress"
            class="carousel-progress bg-grey-80 w-full mt-4 relative max-w-md mx-auto xl:hidden"
        >
            <div
                class="carousel-progress__current bg-navy-blue h-full absolute left-0 top-0"
                :style="`width: ${progress * 100}%`"
            />
        </div>
        <div
            v-if="carousel && dotAutoProgress && carousel.track.details.slides.length > 1"
            class="carousel-auto-progress flex justify-center items-center w-full mt-4 md:mt-6 relative max-w-md mx-auto"
        >
            <button
                v-for="(dot, index, key) in carousel.track.details.slides.length"
                :key="key"
                ref="progressDot"
                :class="{'ml-2': index > 0}"
                class="carousel-auto-progress__dot w-24 bg-white"
                @click="goTo(index)"
            ></button>
        </div>
        <div
            v-if="carousel && mobileDots"
            class="md:hidden w-full flex justify-center items-center mt-6 mx-auto"
        >
            <button
                v-for="(dot, index, key) in carousel.track.details.slides.length"
                :key="key"
                :class="{'ml-4': index > 0, 'bg-navy-blue': index === currentIndex, 'bg-grey-63': index !== currentIndex}"
                class="w-2 h-2 rounded-full"
                @click="goTo(index)"
            ></button>
        </div>
    </div>
</template>

<script>
    import imagesLoaded from 'imagesloaded';
    import 'keen-slider/keen-slider.min.css';
    import KeenSlider from 'keen-slider';

    export default {
        props: {
            options: { type: Object, default: () => {}},
            mobileProgress: { type: Boolean, default: false },
            mobileDots: { type: Boolean, default: false },
            dotAutoProgress: { type: Boolean, default: false },
            desktopArrows: { type: Boolean, default: false },
            desktopCenter: { type: Boolean, default: false },
            arrowsStyle: { type: String, default: 'dark' },
            arrowsSize: { type: String, default: 'L' },
            interruptAutoProgress: { type: Boolean, default: false },
            autoProgressDuration: { type: Number, default: 4}
        },

        data: () => ({
            carousel: null,
            currentIndex: 0,
            progress: 0,
            autoInterval: null,
            opts: {},
            slidesWidth: 0,
            carouselWidth: 0,
            centerAdjust: ''
        }),

        computed: {
            centerValue() {
                return (this.carousel && this.desktopCenter)
                    ? (this.carousel.track.details.slides.length * this.options.slides.spacing) / 2
                    : 0
            }
        },

        watch: {
            progress() {
                // Check that progress doesn't go over 100%
                if (this.progress > 1) this.progress = 1;
            },

            currentIndex() {
                // Emit event when current index change
                this.$emit('slide-change', this.currentIndex);
            },

            carousel() {
                if (this.carousel && this.dotAutoProgress) {
                    this.$nextTick(() => {
                        // Launch progress bar anim
                        this.$refs.progressDot[this.currentIndex].classList.add('active');
                    });
                }
            },

            interruptAutoProgress() {
                // If we must interrupt
                if (this.interruptAutoProgress) {
                    // Stop interval
                    this.clearInterval();

                    // Start progress on current dot
                    this.$refs.progressDot[this.currentIndex].classList.remove('active');
                    this.$refs.progressDot[this.currentIndex].classList.add('done');
                } else {
                    // Stop interval
                    this.clearInterval();

                    this.$refs.progressDot[this.currentIndex].classList.remove('done');
                    setTimeout(() => {
                        this.$refs.progressDot[this.currentIndex].classList.add('active');
                    }, 100);

                    this.setInterval();
                }
            }
        },

        mounted() {
            // Copy options
            this.opts = JSON.parse(JSON.stringify(this.options));

            // Add events
            this.opts.initial = this.currentIndex;

            // On slide change, update current index
            this.opts.slideChanged = (s) => {
                this.currentIndex = s.track.details.rel;

                if (this.dotAutoProgress && this.carousel.track.details.slides.length > 1) {

                    // Delete interval
                    this.clearInterval();

                    // Remove all progress on dots
                    this.clearAllProgressiveDots();

                    // Add active to current dot
                    this.$refs.progressDot[this.currentIndex]?.classList?.add('active');

                    // Reset interval
                    this.setInterval();
                }
            }

            // If we want the progress
            if (this.mobileProgress) {
                // On drag, update progress
                this.opts.detailsChanged = (s) => {
                    this.progress = s.track.details.progress;
                };
            }

            this.opts.created = () => {
                // When images are loaded
                imagesLoaded('.carousel', () => {
                    // Reupdate the carousel with the params
                    this.carousel.update(this.opts, 0);

                    this.$nextTick(() => {
                        // Get the width of carousel elements
                        this.updateWidths();
                    })

                    // Set resize event
                    window.addEventListener('resize', this.updateWidths);
                });
            }

            // Init the carousel
            this.carousel = new KeenSlider(this.$refs.container, this.opts);

            // If we want autoplay
            if (this.dotAutoProgress && this.carousel.track.details.slides.length > 1) {
                // Launch interval
                this.setInterval();
            }

            if (this.desktopCenter) {
                this.centerAdjust = window.innerWidth > 1200 ? `margin-left: -${this.centerValue}px;` : '';
            }
        },

        beforeUnmount() {
            if (this.carousel) this.carousel.destroy()
        },

        methods: {
            // Move the carousel to the index in parameter
            goTo(index) {
                if (this.carousel) {
                    this.carousel.moveToIdx(index);
                }
            },

            // Remove the interval
            clearInterval() {
                if (this.autoInterval) clearInterval(this.autoInterval);
            },

            // Init the interval for auto mode
            setInterval() {
                // Init interval
                this.autoInterval = setInterval(() => {
                    // Remove progress from previous dot
                    this.$refs.progressDot[this.currentIndex].classList.remove('active');
                    this.$refs.progressDot[this.currentIndex].classList.remove('done');

                    // Update the current index
                    if (this.currentIndex + 1 > this.carousel.track.details.slides.length - 1) this.currentIndex = 0;
                    else this.currentIndex++;

                    // Start progress on current dot
                    this.$refs.progressDot[this.currentIndex].classList.remove('done');
                    this.$refs.progressDot[this.currentIndex].classList.add('active');

                    // Move the carousel to the new index
                    this.goTo(this.currentIndex);
                }, this.autoProgressDuration * 1000)
            },

            // Remove the progress from all "progress" dots
            clearAllProgressiveDots() {
                this.$refs.progressDot.forEach((dot) => {
                    dot.classList.remove('active');
                    dot.classList.remove('done');
                });
            },

            updateWidths() {
                // Get slides
                const slides = this.$el.querySelectorAll('.keen-slider__slide');

                if (slides.length > 0) {
                    // Get all slides width
                    this.slidesWidth =  (slides[0].clientWidth * slides.length);
                    // Get carousel width
                    this.carouselWidth = this.$refs.carousel.clientWidth;

                    if (this.desktopCenter) {
                        this.centerAdjust = window.innerWidth > 1200 ? `margin-left: -${this.centerValue}px;` : '';
                    }
                }
            }
        }
    }
</script>

<style lang="scss">
    .keen-slider:not([data-keen-slider-disabled]) {
        @apply overflow-visible;
    }

    .carousel-progress {
        height: 0.1875rem;
    }

    .carousel-auto-progress {
        &__dot {
            @apply relative;
            height: 0.1875rem;

            &:before {
                content: '';
                @apply w-0 h-full bg-navy-blue absolute top-0 left-0;
            }

            &.active {
                &:before {
                    transition: width 4s linear;
                    @apply w-full;
                }
            }

            &.done {
                &:before {
                    @apply w-full;
                }
            }
        }
    }
</style>
