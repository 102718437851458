<template>
    <div v-if="text" class="legal-notice grid-container py-10 bg-white">
        <div class="grid-row">
            <div class="grid-col w-full">
                <div class="cms-content border border-grey-80 py-6 px-4 md:py-12 md:px-16 text-grey-40 flex flex-col items-start">
                    <div
                        ref="container"
                        :class="{ 'active': contentHeight > containerHeight }"
                        class="legal-notice__container relative w-full max-h-28 overflow-hidden"
                    >
                        <div ref="content" class="legal-notice__content" v-html="JSON.parse(text)"></div>
                    </div>
                    <button v-if="contentHeight > containerHeight" class="text-button-small text-blue mt-3" @click="openState = !openState">
                        <span v-if="openState">{{ seeLessText }}</span>
                        <span v-if="!openState">{{ seeMoreText }}</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { gsap } from '@lahautesociete/gsap';
    export default {
        name: 'LegalNotice',

        props: {
            text: { type: String, default: '' },
            seeMoreText: { type: String, default: 'See more' },
            seeLessText: { type: String, default: 'See less' },
        },

        data: () => ({
            openState: false,
            containerHeight: 112,
            contentHeight: 0
        }),

        watch: {
            openState() {
                this.$nextTick(() => {
                    gsap.to(this.$refs.container, {
                        maxHeight: (this.openState) ? this.contentHeight : 112,
                        duration: 0.3,
                        ease: 'power2.out'
                    });

                    this.$refs.container.classList.toggle('opened');
                });
            }
        },

        mounted() {
            this.$nextTick(() => {
                this.contentHeight = this.$refs.content.clientHeight;
            });
        }
    }
</script>

<style lang="scss">
    .legal-notice {
        &__container.active:not(.opened) {
            &:after {
                content: '';
                @apply block w-full h-8 absolute bottom-0 left-0;
                background: linear-gradient(to top, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
            }
        }
    }
</style>
