<template>
    <div ref="filters" class="overview-product-filters flex-shrink-0">
        <div class="overview-product-filters__inner pt-14 px-6 pb-36 lg:p-0">
            <h3 class="mb-6 lg:hidden"><strong>{{ translations.filters }}</strong></h3>

            <div class="overview-product-filters__order border-t border-grey-80 py-4 lg:hidden">
                <div class="overview-product-filters__filter-opener flex justify-between" @click="togglePanel">
                    <h4 class="body-2"><strong>{{ translations.orderBy }}</strong></h4>
                    <img src="@/static/images/icons/chevron-down.svg" />
                </div>
                <div class="overview-product-filters__filter-content overflow-hidden h-0">
                    <fieldset class="pt-4">
                        <div v-for="(ord, index) in orders" :key="`order-radio-${index}`" class="flex items-center">
                            <input type="radio" :id="ord.value" v-model="order" :value="ord">
                            <label :for="ord.value" class="body-2 ml-2">{{ ord.text }}</label>
                        </div>
                    </fieldset>
                </div>
            </div>

            <div class="overview-product-filters__filter overview-product-filters__filter--desk opened overview-product-filters__needs border-t border-grey-80 pt-4 pb-6">
                <div class="overview-product-filters__filter-opener flex justify-between" @click="togglePanel">
                    <h4 class="body-2"><strong>{{ translations.needs }}</strong></h4>
                    <img src="@/static/images/icons/chevron-down.svg" />
                </div>

                <div class="overview-product-filters__filter-content overflow-hidden">
                    <div class="flex flex-wrap justify-start items-start pt-1 pb-0.5">
                        <div
                            v-for="(need, index) in needs"
                            :key="`need-checkbox-${index}`"
                            class="filter-checkbox mt-3 mr-2"
                        >
                            <input type="checkbox" :id="`need-${need.id}`" :value="need.slug" v-model="filters.needs" :disabled="!availableFilters?.needs?.includes(need.slug)"/>
                            <label :for="`need-${need.id}`">{{ need.title }}</label>
                        </div>
                    </div>
                </div>
            </div>

            <div class="overview-product-filters__filter overview-product-filters__filter--desk overview-product-filters__brands border-t border-grey-80 pt-4 pb-6">
                <div class="overview-product-filters__filter-opener flex justify-between" @click="togglePanel">
                    <h4 class="body-2"><strong>{{ translations.brands }}</strong></h4>
                    <img src="@/static/images/icons/chevron-down.svg" />
                </div>

                <div class="overview-product-filters__filter-content overflow-hidden lg:h-0">
                    <div class="flex flex-wrap justify-start items-start pt-1 pb-0.5">
                        <div
                            v-for="(brand, index) in brands"
                            :key="`brand-checkbox-${index}`"
                            class="filter-checkbox mt-3 mr-2"
                        >
                            <input type="checkbox" :id="`brand-${brand.id}`" :value="brand.slug" v-model="filters.brands" :disabled="!availableFilters?.brands?.includes(brand.slug)" />
                            <label :for="`brand-${brand.id}`">{{ brand.title }}</label>
                        </div>
                    </div>
                </div>
            </div>

            <div class="overview-product-filters__filter overview-product-filters__filter--desk overview-product-filters__forms border-t border-grey-80 pt-4 pb-6">
                <div class="overview-product-filters__filter-opener flex justify-between" @click="togglePanel">
                    <h4 class="body-2"><strong>{{ translations.forms }}</strong></h4>
                    <img src="@/static/images/icons/chevron-down.svg" />

                </div>

                <div class="overview-product-filters__filter-content overflow-hidden lg:h-0">
                    <div class="flex flex-wrap justify-start items-start pt-1 pb-0.5">
                        <div
                            v-for="(form, index) in forms"
                            :key="`form-checkbox-${index}`"
                            class="filter-checkbox mt-3 mr-2"
                        >
                            <input type="checkbox" :id="`form-${form.id}`" :value="form.slug" v-model="filters.forms" :disabled="!availableFilters?.forms?.includes(form.slug)" />
                            <label :for="`form-${form.id}`">{{ form.title }}</label>
                        </div>
                    </div>
                </div>
            </div>

<!--            <div class="overview-product-filters__promos border-t border-grey-80 pt-4 pb-6">-->
<!--                <h4 class="body-2"><strong>{{ translations.promo }}</strong></h4>-->
<!--            </div>-->

        </div>
        <div ref="buttonsContainer" class="overview-product-filters__buttons absolute bottom-0 left-0 right-0 w-full flex justify-between items-center px-6 py-4 bg-white lg:hidden">
            <button class="btn btn--M btn--ghost-blue" @click="resetFilters">{{ translations.eraseFilters }}</button>
            <button class="btn btn--M btn--plain-blue" @click="closeFilters">{{ translations.applyFilters }}</button>
        </div>
        <button class="overview-product-filters__cross absolute top-4 right-6 lg:hidden" @click="closeFilters">
            <img src="@/static/images/icons/cross.svg" />
        </button>
    </div>
</template>

<script>
    import { gsap } from '@lahautesociete/gsap';

    export default {
        name: 'OverviewProductFilters',

        props: {
            brands: { type: Array, default: () => [] },
            needs: { type: Array, default: () => [] },
            forms: { type: Array, default: () => [] },
            orders: { type: Object, default: () => {} },
            filterState: { type: Boolean, default: false },
            availableFilters: { type: Object, default: () => {} },
            translations: { type: Object, default: () => {}, required: true }
        },

        data: () => ({
            filters: {
                needs: [],
                brands: [],
                forms: [],
                hiddenFilter: []
            },
            order: null,
            orderState: false,
            breakpoint: 1024
        }),

        watch: {
            filterState() {
                if (window.innerWidth < this.breakpoint) {
                    // Show fitlers on mobile
                    gsap.to(this.$refs.filters, {
                        y: (this.filterState) ? '0%' : '100%',
                        duration: 0.4,
                        ease: 'power3.out'
                    });

                    this.$refs.filters.addEventListener('scroll', () => {
                        gsap.set(this.$refs.buttonsContainer, {
                            y: this.$refs.filters.scrollTop
                        })
                    });

                    // Prevent scroll
                    gsap.set('html, body', {
                        overflow: (this.filterState) ? 'hidden' : 'visible'
                    });
                } else {
                    // Show fitlers on mobile
                    gsap.to(this.$refs.filters, {
                        width: (this.filterState) ? '25%' : '0%',
                        duration: 0.4,
                        ease: 'power3.out'
                    });
                }
            },

            order() {
                this.$emit('order-update', this.order);
            },

            'filters.needs'() { this.filters.hiddenFilter = []; this.$emit('filters-update', this.filters); },
            'filters.brands'() { this.filters.hiddenFilter = []; this.$emit('filters-update', this.filters); },
            'filters.forms'() { this.filters.hiddenFilter = []; this.$emit('filters-update', this.filters); }
        },

        created() {
            this.order = this.orders.RELEVANCE;

            const pageGetParams = new URLSearchParams(window.location.search);

            if (pageGetParams.get('brand')) { this.filters.brands.push(pageGetParams.get('brand')); }
            if (pageGetParams.get('need')) { this.filters.needs.push(pageGetParams.get('need')); }
            if (pageGetParams.get('form')) { this.filters.forms.push(pageGetParams.get('form')); }
            if (pageGetParams.get('hiddenFilter')) { this.filters.hiddenFilter.push(pageGetParams.get('hiddenFilter')); }
            if (pageGetParams.get('brand') || pageGetParams.get('need') || pageGetParams.get('form') || pageGetParams.get('hiddenFilter')) {
                this.$emit('filters-update', this.filters);
            }
        },

        methods: {
            resetFilters() {
                this.filters = {
                    needs: [],
                    brands: [],
                    forms: []
                };
            },

            closeFilters() {
                this.$emit('filter-state-update', false);
            },

            togglePanel(event) {
                const opener = event.currentTarget;
                const parent = opener.parentNode;
                const content = parent.querySelector('.overview-product-filters__filter-content');
                const subcontent = parent.querySelector('.overview-product-filters__filter-content > *');

                if (
                    window.innerWidth < 1024 && !parent.classList.contains('overview-product-filters__filter--desk')
                    || window.innerWidth >= 1024 && parent.classList.contains('overview-product-filters__filter--desk')
                ) {
                    gsap.to(content, {
                        height: (parent.classList.contains('opened')) ? 0 : subcontent.clientHeight + 5,
                        duration: 0.3,
                        ease: 'power2.out'
                    });

                    parent.classList.toggle('opened');
                }
            }
        }
    }
</script>

<style lang="scss">
    .overview-product-filters {
        @apply w-screen h-screen bg-white fixed top-0 left-0 right-0 z-50 overflow-y-scroll transform translate-y-full;

        @screen lg {
            @apply w-0 h-auto relative top-0 z-0 overflow-hidden transform-none;

            &__inner {
                @apply px-4;
            }
        }

        @screen xl {
            &__inner {
                @apply px-5;
            }
        }

        &__filter {
            &--desk {
                .overview-product-filters__filter-opener img {
                    @apply hidden;

                    @screen lg {
                        @apply block;
                    }
                }
            }
        }

        &__filter-opener {
            @apply cursor-pointer;
        }
    }
</style>
