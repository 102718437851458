<template>
    <div class="collapse-panel cursor-pointer flex items-center" @click="openState = !openState">
        <slot name="precontent"></slot>
        <div class="flex-grow">
            <slot name="heading"></slot>
            <div ref="container" class="collapse-panel__content h-0 overflow-hidden">
                <div ref="content">
                    <slot name="text"></slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { gsap } from '@lahautesociete/gsap';

    export default {
        name: 'CollapsePanel',

        data: () => ({
            openState: false
        }),

        mounted() {
            window.addEventListener('resize', this.resizeHandler)
        },

        watch: {
            openState() {
                gsap.to(this.$refs.container, {
                    height: (this.openState) ? this.$refs.content.clientHeight : 0,
                    duration: 0.5,
                    ease: 'power2.out'
                });
            }
        },

        methods: {
            resizeHandler() {
                gsap.set(this.$refs.container, {
                    height: (this.openState) ? this.$refs.content.clientHeight : 0
                });
            }
        }
    }
</script>
