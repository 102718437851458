<template>
    <div class="overview-product-heros relative">
        <transition-group :name="mounted > 1 ? 'fade' : ''">
            <HeroSimple
                v-for="(brand, index) in brands"
                v-show="currentBrands.length === 1 && currentBrands[0] === brand.slug"
                :key="`hero-brand-${index}`"
                :hero="{
                    heading: brand.title,
                    image: brand.heroImage
                }"
                class="absolute inset-0"
            />
        </transition-group>
        <transition :name="mounted > 1 ? 'fade' : ''">
            <HeroSimple
                v-show="currentBrands.length === 0 || currentBrands.length > 1"
                :hero="baseHero"
                class="absolute inset-0"
            />
        </transition>
    </div>
</template>

<script>
    import HeroSimple from '@/app/components/HeroSimple.vue';
    export default {
        name: 'OverviewProductHeros',

        components: { HeroSimple },

        props: {
            baseHero: { type: Object, default: () => {} },
            brands: { type: Array, default: () => [] },
            currentBrands: { type: Array, default: () => []}
        },

        data: () => ({
            mounted: 0
        }),

        watch: {
            currentBrands() {
                this.mounted++;
            }
        }
    }
</script>

<style lang="scss">
    .overview-product-heros {
        height: 12.5rem;

        @screen md {
            height: 21.875rem;
        }
    }

    .fade-enter-active,
    .fade-leave-active {
        transition: all 0.5s ease-out;
    }

    .fade-enter-from,
    .fade-leave-to {
        opacity: 0;
    }
</style>
