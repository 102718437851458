<template>
    <div ref="submenu" class="header-submenu">
        <button
            :class="[
                `text-nav-${mainLinkStyle}`,
                {
                    'header-nav__nav-item--secondary': mainLinkStyle === 'secondary',
                    'header-nav__nav-item--main xl:px-3.5 xl:py-2 xl:rounded-3xl': mainLinkStyle === 'main'
                }
            ]"
            class="header-nav__nav-item" @click="toggleState"
        >
            {{ submenu.heading }}
        </button>

        <div
            ref="submenuContainer"
            class="header-submenu__content absolute w-full left-full top-0 xl:top-auto xl:left-0 xl:right-0 z-30 overflow-hidden hidden px-6 py-10 xl:px-0 xl:py-0"
        >
            <div
                ref="submenuContent"
                class="header-submenu__subcontent xl:pt-4 pb-26 xl:pb-14 bg-white transform xl:-translate-y-full"
            >
                <div ref="submenuInner" class="grid-container opacity-0">
                    <button class="header-submenu__back-link text-button-small text-blue" @click="toggleState">Retour</button>
                    <div class="grid-row">
                        <div class="grid-col w-full xl:w-1/2 xl:pt-6 xl:columns-2">
                            <!-- Level 1 -->
                            <div
                                v-for="(child, childIndex,childKey) in submenu.children"
                                :key="`level-1-${childKey}`"
                                :class="{'xl:mt-10': childIndex !== 0}"
                                class="header-submenu__menu xl:pl-4 break-inside-avoid-column"
                            >
                                <a
                                    v-if="child.type === 'link' && child.link && child.link.url"
                                    :href="child.link.url"
                                    :target="child.link.target"
                                    class="body-2"
                                >{{ child.link.label }}</a>

                                <div v-if="child.type === 'submenu'" class="header-submenu__menu-block">
                                    <p class="text-nav-main text-blue mb-4">{{ child.heading }}</p>

                                    <!-- Level 2 -->
                                    <div
                                        v-for="(subchild, subchildIndex, subchildKey) in child.children"
                                        :key="`level-2-${subchildKey}`"
                                        class=""
                                    >
                                        <a
                                            v-if="subchild && subchild.link && subchild.link.url"
                                            :href="subchild.link.url"
                                            :target="subchild.link.target"
                                            :class="{'mt-4 xl:mt-3': subchildIndex > 0}"
                                            class="inline-flex body-2"
                                        >{{ subchild.link.label }}</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="header-submenu__cards flex flex-col xl:flex-row items-center w-full xl:w-1/2 mt-8 xl:mt-0">
                            <div
                                v-for="(card, index, key) in submenu.cards"
                                :key="`submenu-card-${key}`"
                                :class="{'mt-4 xl:mt-0': index > 0}"
                                class="grid-col w-full xl:w-1/2 self-stretch"
                            >
                                <a
                                    v-if="card && card.link && card.link.url"
                                    :href="card.link.url"
                                    :target="card.link.target"
                                    class="header-submenu__card block relative overflow-hidden rounded-lg md:rounded-2xl"
                                >
                                    <img :src="card.image.url" :alt="card.image.alt" class="object-cover w-full h-full"/>
                                    <span class="header-submenu__card-image-overlay overlay--black-50 absolute inset-0 w-full h-full"></span>
                                    <span class="flex flex-col items-start absolute bottom-4 xl:bottom-6 left-4 right-4 text-white">
                                        <span class="h4">{{ card.heading }}</span>
                                        <span class="btn-text btn-text--white btn-text--M mt-4 xl:mt-6">{{ card.link.label }}</span>
                                    </span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { gsap } from '@lahautesociete/gsap';

    export default {
        name: 'HeaderSubmenu',

        props: {
            submenu: { type: Object, required: true },
            mainLinkStyle: { type: String, default: 'main'},
            generalState: { type: Boolean, default: false }
        },

        data: () => ({
            // false => closed, true => opened
            menuState: false,

            breakpoint: 1200
        }),

        methods: {
            toggleState() {
                // Toggle menu state
                this.menuState = !this.menuState;

                // Show/hide panel
                this.togglePanel(this.menuState, 0);

                // Show/hide content
                this.toggleContent(this.menuState, (this.menuState) ? 0.5 : 0);

                // Toggle events
                this.toggleEvents(this.menuState);

                // Emit event
                this.$emit('menu-state-change', this.menuState);
            },

            checkClickHandler(event) {
                // If the element clicked isn't in the submenu
                if (!this.$refs.submenu.contains(event.target)) {
                    this.menuState = false;

                    this.toggleContent(false, 0);

                    // if we don't open a new menu
                    if (!event.target.classList.contains('header-nav__nav-item--main') && !event.target.classList.contains('header-nav__nav-item--secondary')) {
                        this.togglePanel(false, 0);

                        // Emit event
                        this.$emit('menu-state-change', this.menuState);
                    } else {
                        this.togglePanel(false, 0.5);
                    }
                }
            },

            togglePanel(menuState, delay) {
                // On mobile
                if (window.innerWidth < this.breakpoint) {
                    // Enable container
                    gsap.set(this.$refs.submenuContainer, {
                        display: (menuState) ? 'block' : 'none',
                    });
                }
                else {
                    // Enable container
                    gsap.set(this.$refs.submenuContainer, {
                        display: (menuState) ? 'block' : 'none',
                        delay: (menuState) ? delay : delay + 0.3
                    });

                    // Show the content
                    gsap.to(this.$refs.submenuContent, {
                        y: (menuState) ? '0%' : '-100%',
                        duration: 0.2,
                        delay: delay,
                        ease: 'power3.out'
                    });
                }
            },

            toggleContent(menuState, delay) {
                // Show the content
                gsap.fromTo(this.$refs.submenuInner, {
                    opacity: (menuState) ? 0 : 1,
                },{
                    opacity: (menuState) ? 1 : 0,
                    duration: (window.innerWidth < this.breakpoint) ? 0 : (menuState) ? 0.5 : 0.2,
                    delay: (window.innerWidth < this.breakpoint) ? 0 : delay,
                    ease: 'power2.out'
                    });
            },

            toggleEvents(menuState) {
                if (menuState) {
                    document.querySelector('header').addEventListener('click', this.checkClickHandler)
                } else {
                    document.querySelector('header').removeEventListener('click', this.checkClickHandler);
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .header-submenu {
        &__content {
            @apply top-0;
            max-width: 120rem;

            @screen xl {
                top: 4.5rem;
            }
        }

        &__subcontent {
            .grid-container {
                @apply px-0;

                @screen xl {
                    @apply px-14;
                }
            }
        }

        &__back-link {
            @apply inline-flex items-center;

            &:before {
                content: '';
                background-image: url('@/static/images/icons/chevron-left-blue.svg');
                @apply block w-6 h-6 mr-1;
            }

            @screen xl {
                @apply hidden;
            }
        }

        &__menu-block {
            &:before {
                content: '';
                @apply block w-full h-px my-6 bg-navy-blue opacity-40;

                @screen xl {
                    @apply hidden;
                }
            }
        }

        &__menu:first-child {
            @apply mt-8;

            @screen xl {
                @apply mt-0;
            }

            .header-submenu__menu-block:before {
                @apply hidden;
            }
        }

        &__card {
            height: 11.25rem;
            max-width: 24.375rem;

            @screen xl {
                height: 25rem;
            }
        }
    }
</style>
