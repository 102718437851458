<template>
    <div ref="root" class="flex flex-col w-full">
        <slot></slot>

        <template v-if="isFieldVisible">
            <label for="brand" class="input-label mt-6">Marque</label>
            <select name="message[Marque]" id="brand" class="input-select">
                <option value="">Choissisez une marque</option>
                <option v-for="(brand, key) in brands" :key="key" :value="key">{{ brand }}</option>
            </select>
        </template>
    </div>
</template>

<script>
export default {
    name: "FormConditionalField",

    props: {
        conditionValue: {
            type: String,
            required: true,
        },
        brands: {
            type: Array,
            required: true,
        },
    },

    data() {
        return {
            testedField: null,
            testedFieldValue: null,
        }
    },

    computed: {
        isFieldVisible() {
            return this.testedFieldValue === this.conditionValue;
        },
    },

    mounted() {
        this.testedField = this.$refs.root.querySelector('.input-select');

        this.testedField.addEventListener('input', this.inputHandler);
    },

    unmounted() {
        this.testedField.removeEventListener('input', this.inputHandler);
    },

    methods: {
        inputHandler(event) {
            const inputSelect = event.target;
            this.testedFieldValue = inputSelect.options[inputSelect.selectedIndex].text;
        },
    },
}
</script>
