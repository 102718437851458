<template>
    <div class="video-carousel relative">
        <carousel
            :options="{
                mode: 'free',
                defaultAnimation: {
                    duration: 1400
                },
                slides: {
                    autoWidth: true,
                    perView: 'auto',
                    spacing: 8
                },
                breakpoints: {
                    '(min-width: 768px)': {
                        slides: {
                            autoWidth: true,
                            perView: 'auto',
                            spacing: 64
                        }
                    }
                }
            }"
            :desktop-arrows="true"
            arrows-style="dark"
            arrows-size="L"
            :dot-auto-progress="true"
            :interrupt-auto-progress="videoPlaying"
            :auto-progress-duration="6"
            @slide-change="updateCurrentIndex($event)"
            class="w-full"
        >
            <div
                v-for="(video, index, key) in videos"
                :key="`image-slide-${key}`"
                :class="`number-slide${index + 1}`"
                class="video-carousel__slide keen-slider__slide w-full relative rounded-2xl overflow-hidden"
            >
                <video
                    :poster="video.poster"
                    class="w-full"
                    @play="videoPlayed"
                    @pause="videoPlaying = false"
                    @click="playVideo"
                >
                    <source :src="video.url" />
                </video>
                <img src="@/static/images/icons/video-play.svg" class="video-carousel__play-btn cursor-pointer absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 pointer-events-none" />
            </div>
        </carousel>
    </div>
</template>

<script>
    import Carousel from "@/app/components/Carousel.vue";
    import { gsap } from "@lahautesociete/gsap";

    export default {
        name: 'VideoCarousel',

        components: { Carousel },

        props: {
            videos: { type: Array, default: () => []}
        },

        data: () => ({
            currentIndex: 0,
            videoPlaying: false
        }),

        methods: {
            playVideo(event) {
                const video = event.target;
                const videoContainer = video.parentNode;

                // Add controls to the video
                video.setAttribute('controls', true);

                // Hide the play btn
                gsap.to(videoContainer.querySelector('.video-carousel__play-btn'), {
                    display: 'none',
                    opacity: 0,
                    duration: 0.4,
                    ease: 'power2.out'
                });
            },

            videoPlayed() {
                this.videoPlaying = true;
            },

            updateCurrentIndex() {
                // Get all videos
                const videos = this.$el.querySelectorAll('video');

                // Pause them
                videos.forEach((video) => {
                    video.pause();
                });

                this.videoPlaying = false;
            }
        }
    }
</script>

<style lang="scss">
    .video-carousel {
        &__slide {
            min-width: 100%;
            max-width: 100%;
            aspect-ratio: 16/9;

            @screen md {
                min-width: 100%;
                max-width: 100%;
            }
        }

        .carousel__arrow-container {
            @apply left-0;
        }

        .carousel__arrow-left {
            @apply absolute -left-8;
        }

        .carousel__arrow-right {
            @apply absolute -right-8;
        }

        .carousel-auto-progress {
            @apply absolute -bottom-7 left-1/2 transform -translate-x-1/2;
        }

        .carousel-auto-progress {
            &__dot {
                &.active {
                    &:before {
                        transition: width 6s linear;
                        @apply w-full;
                    }
                }
            }
        }
    }
</style>
