<template>
    <div class="hero-slider-component relative md:flex md:justify-end">
        <div class="grid-col w-full md:w-8/12 md:ml-4/12 -ml-2 md:ml-0">
            <carousel
                :options="{
                        mode: 'free',
                        defaultAnimation: {
                            duration: 1400
                        },
                        slides: {
                            origin: 0,
                            autoWidth: true,
                            perView: 'auto',
                            spacing: 8
                        },
                        breakpoints: {
                            '(min-width: 768px)': {
                                slides: {
                                    origin: 0,
                                    autoWidth: true,
                                    perView: 'auto',
                                    spacing: 12
                                }
                            }
                        }
                    }"
                :desktop-arrows="true"
                arrows-style="dark"
                arrows-size="L"
                :dot-auto-progress="true"
                @slide-change="updateCurrentIndex($event)"
            >
                <div
                    v-for="(slide, index, key) in slides"
                    :key="`image-slide-${key}`"
                    :class="`number-slide${index + 1}`"
                    class="hero-slider-component__slide keen-slider__slide rounded-lg md:rounded-2xl overflow-hidden"
                >
                    <img :src="slide.image.url" :alt="slide.image.alt" class="absolute inset-0 object-cover w-full h-full" loading="lazy"/>
                </div>
            </carousel>
        </div>
        <div class="grid-col w-full md:w-6/12 lg:w-5/12 md:ml-1/12 relative md:absolute md:left-0 md:top-1/2 md:transform md:-translate-y-1/2">
            <div ref="textContainer" class="hero-slider-component__text-container bg-white -mt-12 md:mt-0 mx-auto md:mx-0 overflow-hidden">
                <article
                    v-for="(slide, index, key) in slides"
                    :key="`text-slide-${key}`"
                    ref="textSlide"
                    :class="{'hidden opacity-0': index !== currentIndex }"
                    class="w-full md:h-full px-4 py-6 md:px-12 md:py-10 relative flex flex-col justify-center"
                >
                    <a
                        v-if="slide.brand && slide.brand.url"
                        :href="slide.brand.url"
                        :class="`text-${slide.brand.colorScheme.value}`"
                        class="brand-label md:absolute md:top-10 md:left-12"
                    >
                        {{ slide.brand.title }}
                    </a>
                    <a v-if="slide.link && slide.link.url" :href="slide.link.url" :target="slide.link.target">
                        <h2 v-if="slide.heading" class="h1 mt-6 md:mt-0" v-html="slide.heading" />
                        <div v-if="slide.text" v-html="slide.text" class="body-1 mt-3 md:mt-4"></div>
                        <img src="@/static/images/icons/arrow-right-black.svg" width="24" height="24" class="mt-6 md:mt-16 md:absolute md:bottom-10 md:left-12" />
                    </a>
                    <template v-else>
                        <h2 v-if="slide.heading" class="h1 mt-6 md:mt-0" v-html="slide.heading" />
                        <div v-if="slide.text" v-html="slide.text" class="body-1 mt-3 md:mt-4"></div>
                        <img src="@/static/images/icons/arrow-right-black.svg" width="24" height="24" class="mt-6 md:mt-16 md:absolute md:bottom-10 md:left-12" />
                    </template>
                </article>
            </div>
        </div>
    </div>
</template>

<script>
    import { gsap } from '@lahautesociete/gsap';
    import Carousel from '@/app/components/Carousel.vue';

    export default {
        name: 'HeroSlider',

        props: {
            slides: { type: Array, default: () => [], required: true }
        },

        components: { Carousel },

        data: () => ({
            currentIndex: 0,
        }),

        methods: {
            updateCurrentIndex(index) {
                if (this.tl) this.tl.kill();

                this.tl = gsap.timeline();

                // Hide current text
                this.tl.to(this.$refs.textSlide[this.currentIndex], {
                    opacity: 0,
                    display: 'none',
                    duration: 0.6,
                    ease: 'power2.out'
                });

                this.tl.to(this.$refs.textSlide[index], {
                    display: 'flex',
                    opacity: 1,
                    duration: 0.6,
                    ease: 'power2.out',
                    onComplete: () => {
                        this.currentIndex = index;
                    }
                }, '>');
            }
        }
    }
</script>

<style lang="scss">
    .hero-slider-component {
        &__slide {
            min-width: 279px;
            max-width: 279px;
            height: 257px;

            @screen md {
                min-width: 730px;
                max-width: 730px;
                height: 672px;
            }
        }

        &__text-container {
            max-width: 530px;

            @screen md {
                height: 530px;
            }
        }

        .carousel__arrow-container {
            @apply left-0;
        }

        .carousel__arrow-left {
            @apply absolute left-4;
        }

        .carousel__arrow-right {
            @apply absolute right-0;
        }

        .carousel-auto-progress {
            @apply absolute -bottom-7 left-1/2 transform -translate-x-1/2;
        }
    }
</style>
