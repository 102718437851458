<template>
    <div ref="container" class="timeline relative md:h-screen md:flex md:items-center overflow-hidden py-10 md:py-20">
        <div class="timeline__bg absolute inset-0">
            <picture>
                <source media="(min-width: 768px)" :srcset="background.desktop.url" width="1920" height="580" />
                <img
                    :src="background.mobile.url"
                    :alt="background.mobile.alt"
                    ref="bg"
                    width="768"
                    height="580"
                    aria-hidden="true"
                    class="absolute inset-0 w-full h-full object-cover object-top md:max-w-none md:w-auto md:min-w-full md:object-fill md:left-0"
                />
            </picture>
            <div class="overlay--grey-11-40 absolute inset-0 w-full h-full" />
        </div>
        <div class="grid-container relative w-full">
            <img src="~@/static/images/icons/line-scrollhorizontal.svg" ref="line" class="absolute top-1/2 left-0 max-w-none hidden md:block"/>
            <div class="grid-row">
                <div class="grid-col w-full text-center">
                    <h2 class="text-white">{{ heading }}</h2>
                </div>
            </div>
            <div class="grid-row mt-6 md:mt-10">
                <div class="grid-col w-full">
                    <div ref="stepContainer" class="timeline__step-container relative w-full">
                        <div ref="stepContent" class="inline-flex flex-col md:flex-row md:items-stretch md:flex-nowrap w-full md:w-auto md:max-w-none md:whitespace-nowrap ">
                            <article
                                v-for="(step, index) in steps"
                                :key="`timeline-step-${index}`"
                                ref="step"
                                :class="{'mt-6 md:mt-0 md:ml-20': index > 0 }"
                                class="timeline__step relative flex-shrink-0 bg-white px-4 py-6 md:p-16 w-full"
                            >
                                <div class="w-16 h-16 bg-skin-light rounded-full flex items-center justify-center h4">
                                    <strong>{{ index + 1}}</strong>
                                </div>
                                <h3 class="text-navy-blue mt-6"><strong>{{ step.heading }}</strong></h3>
                                <div class="body-1 text-grey-11 mt-2 whitespace-normal" v-html="step.text"></div>
                            </article>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { gsap } from '@lahautesociete/gsap';
    import { ScrollTrigger } from '@lahautesociete/gsap/ScrollTrigger';

    gsap.registerPlugin(ScrollTrigger);

    export default {
        props: {
            heading: { type: String, default: '' },
            steps: { type: Array, default: () => [] },
            background: { type: Object, default: () => {} }
        },

        mounted() {
            if (window.innerWidth >= 768) {
                this.$nextTick(() => {
                    let marginValue = parseInt(window.getComputedStyle(this.$refs.step[1]).marginLeft.replace('px', ''));
                    let contentWidth = (this.$refs.step[0].offsetWidth * this.$refs.step.length + marginValue * this.$refs.step.length - 1)

                    gsap.to(this.$refs.stepContent, {
                        x: - contentWidth + this.$refs.stepContainer.offsetWidth,
                        ease: 'none',
                        scrollTrigger: {
                            trigger: '.timeline',
                            pin: true,
                            scrub: 1,
                            // snap: 1 / (this.$refs.step.length - 1),
                            end: () => '+=' + window.innerHeight,
                            onUpdate: (self) => {
                                const bgRatio = 0.5;
                                const lineRatio = 0.7;

                                gsap.set(this.$refs.bg, {
                                    x: - self.progress * ((this.$refs.bg.clientWidth - window.innerWidth) * bgRatio)
                                });


                                gsap.set(this.$refs.line, {
                                    x: - self.progress * ((this.$refs.line.clientWidth - window.innerWidth) * lineRatio)
                                })
                            }
                        }
                    });
                });
            }
        }
    }
</script>

<style lang="scss">
    .timeline {

        &__step-container {
            @apply relative;

            &:before {
                content: '';
                @apply h-full bg-skin-light absolute top-0 bottom-0 left-1/2;
                width: 3px;
                margin-left: -1.5px;

                @screen md {
                    @apply hidden;
                }
            }
        }

        &__step {
            @screen md {
                width: 53.4%;
                max-width: 44.375rem;
            }
        }
    }
</style>
