import './assets/styles/main.scss';

import { createApp } from 'vue'
import CsrfInput from '@/app/components/CsrfInput';
import HeaderNav from "@/app/components/HeaderNav";
import Carousel from '@/app/components/Carousel.vue';
import OverviewProductList from '@/app/components/OverviewProductList.vue';
import OverviewProductHeros from '@/app/components/OverviewProductHeros.vue';
import OverviewProductFilters from '@/app/components/OverviewProductFilters.vue';
import OverviewProductFilterUtils from '@/app/components/OverviewProductFilterUtils.vue';
import ProductList from '@/app/components/ProductList.vue';
import HeroSimple from '@/app/components/HeroSimple.vue';
import ProductCard from '@/app/components/ProductCard.vue';
import HeroProductImageSlider from '@/app/components/HeroProductImageSlider.vue';
import CollapsePanel from '@/app/components/CollapsePanel.vue';
import Tabs from '@/app/components/Tabs.vue';
import HeroSlider from '@/app/components/HeroSlider.vue';
import ScrollSlider from '@/app/components/ScrollSlider.vue';
import KeywordsAutoscroll from '@/app/components/KeywordsAutoscroll.vue';
import LegalNotice from '@/app/components/LegalNotice.vue';
import ParallaxBg from '@/app/components/ParallaxBg.vue';
import Timeline from '@/app/components/Timeline.vue';
import FormConditionalField from "@/app/components/FormConditionalField";
import BlockAnimation from "@/app/components/BlockAnimation";
import NewsletterForm from "@/app/components/NewsletterForm";
import BecomeAmbassador from "@/app/components/BecomeAmbassador";
import VideoCarousel from "@/app/components/VideoCarousel.vue";

import Vue3TouchEvents from "vue3-touch-events";

createApp({
    name: 'App',
    components: {
        CsrfInput,
        HeaderNav,
        Carousel,
        OverviewProductList,
        OverviewProductHeros,
        OverviewProductFilters,
        OverviewProductFilterUtils,
        ProductList,
        HeroSimple,
        ProductCard,
        HeroProductImageSlider,
        CollapsePanel,
        Tabs,
        HeroSlider,
        ScrollSlider,
        KeywordsAutoscroll,
        LegalNotice,
        ParallaxBg,
        Timeline,
        FormConditionalField,
        BlockAnimation,
        NewsletterForm,
        BecomeAmbassador,
        VideoCarousel
    }
}).use(Vue3TouchEvents).mount('#app');
