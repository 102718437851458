<template>
    <div class="overview-product-filter-utils pt-4 pb-6 lg:py-8">
        <div class="grid-container">
            <div class="grid-row">
                <div ref="title" class="grid-col flex items-center">
                    <p class="overview-product-filter-utils__heading h4 whitespace-nowrap"><strong>{{ productsCount }} {{ productsCount === 1 ? translations.product : translations.products }}</strong></p>
                </div>
                <div :class="{'lg:w-9/12': filterState, 'flex-grow': !filterState}" class="grid-col lg:flex lg:items-center lg:justify-between">
                    <button class="btn btn--S btn--ghost-blue" @click="$emit('toggle-filters')">
                        {{ filterState ? translations.hideFilters : translations.showFilters }}
                        <img src="~@/static/images/icons/filter.svg" />
                    </button>

                    <div class="hidden lg:flex lg:items-center">
                        <label class="body-3 mr-1.5">{{ translations.orderBy}}</label>
                        <select v-model="order" class="body-3 text-grey-40 border-b border-grey-40 h-6">
                            <option
                                v-for="(order, index) in orders"
                                :key="`order-${index}`"
                                :value="order"
                            >{{ order.text }}</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { gsap } from '@lahautesociete/gsap';
    export default {
        name: 'OverviewProductFilterUtils',

        props: {
            productsCount: { type: Number, default: 0 },
            orders: { type: Object, default: () => {} },
            translations: { type: Object, default: () => {} },
            filterState: { type: Boolean, default: false }
        },

        data: () => ({
            order: null,
            titleWidth: 0,
            breakpoint: 1024
        }),

        watch: {
            order() {
                this.$emit('order-update', this.order);
            },

            filterState() {
                const paddingValue = parseInt(window.getComputedStyle(this.$refs.title, null).getPropertyValue('padding-left').replace('px', ''));
                if (window.innerWidth >= 1024) {
                    gsap.to(this.$refs.title, {
                        width: (this.filterState) ? '25%' : this.$refs.title.querySelector('.overview-product-filter-utils__heading').clientWidth + 2 * paddingValue,
                        duration: 0.25,
                        ease: 'power2.out'
                    });
                }
            }
        },

        mounted() {
            this.order = this.orders.RELEVANCE;

            this.$nextTick(() => {
                this.titleWidth = this.$refs.title.clientWidth;
            })
        }
    }
</script>
