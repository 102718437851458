<template>
    <div id="parallax-bg" class="parallax-bg absolute inset-0 w-full h-full">
        <slot></slot>
    </div>
</template>

<script>
    import { gsap } from '@lahautesociete/gsap';
    import { ScrollTrigger } from 'gsap/ScrollTrigger';

    gsap.registerPlugin(ScrollTrigger);

    export default {
        name: 'ParallaxBg',

        data: () => ({
            img: null,
            ratio: 1.5,
            breakpoint: 767
        }),

        mounted() {
            this.img = this.$el.querySelector('img');

            if (window.innerWidth > this.breakpoint) {
                this.plainScrollTrigger = ScrollTrigger.create({
                    trigger: '.parallax-bg',
                    start:  `${-window.innerHeight} top`,
                    end: 'bottom top',
                    onUpdate: (self) => {
                        const translateValue = self.progress * (this.$el.clientHeight * this.ratio - this.$el.clientHeight);
                        this.img.style.transform = `translateY(${-translateValue}px)`;
                    }
                });
            }
        }
    }
</script>

<style lang="scss">
    .parallax-bg {
        img {
            @screen md {
                height: 150%;
            }
        }
    }
</style>
