<template>
    <div class="keywords-autoscroll py-10 md:pt-16 md:pb-18">
        <div ref="wordsContainer" class="keywords-autoscroll__words-container text-navy-blue" :style="`animation-duration: ${animationDuration}s`">
            <ul class="keywords-autoscroll__words-content">
                <li v-for="(word, key) in words"
                   :key="`word-${key}`"
                   class="keywords-autoscroll__word key-figure"
                >{{ word }}</li>
            </ul>
            <ul class="keywords-autoscroll__words-content">
                <li v-for="(word, key) in words"
                   :key="`word-${key}`"
                    class="keywords-autoscroll__word key-figure"
                >{{ word }}</li>
            </ul>
            <ul class="keywords-autoscroll__words-content">
                <li v-for="(word, key) in words"
                   :key="`word-${key}`"
                   class="keywords-autoscroll__word key-figure"
                >{{ word }}</li>
            </ul>
        </div>
    </div>
</template>

<script>
    import { gsap } from '@lahautesociete/gsap';

    export default {
        name: 'KeywordsAutoscroll',

        props: {
            words: { type: Array, default: () => [] }
        },

        data: () => ({
            timePerWord: 6,
            timeMultp: 3,
        }),

        computed: {
            animationDuration() {
                return (this.words.length + 1) * this.timePerWord;
            }
        },

        mounted() {
            this.$nextTick(() => {
                this.tl = gsap.timeline({ repeat: -1});

                this.tl.to(this.$refs.wordsContainer, {
                    x: -(2 * this.$refs.wordsContainer.clientWidth / this.timeMultp) + 38,
                    duration: this.timePerWord * this.timeMultp,
                    ease: 'none'
                });
            });
        }
    }
</script>

<style lang="scss">
    .keywords-autoscroll {
        @apply mx-auto w-full whitespace-nowrap overflow-hidden box-border relative;

        &__words-container {
            @apply flex;
        }

        &__words-content {
            @apply flex justify-around list-none p-0 m-0;
        }

        &__word {
            &:not(:first-child):before, &:last-child:after {
                content: '';
                @apply inline-flex w-6 h-6 mx-8 relative -top-1;
                background-image: url('~@/static/images/icons/arrow-right-navy-blue.svg');

                @screen md {
                    @apply mx-14;
                }
            }
        }
    }
</style>
