<template>
    <div class="overview-product-list relative pb-10 lg:pb-20">
        <div class="overview-product-list__bg bg-white absolute left-0 bottom-0 right-0"></div>
        <div class="overview-product-list__content relative">
            <OverviewProductHeros
                :base-hero="baseHero"
                :brands="brands"
                :current-brands="filters?.brands"
            ></OverviewProductHeros>

            <OverviewProductFilterUtils
                :products-count="filteredProducts.length"
                :orders="orders"
                :translations="translations"
                :filter-state="filterState"
                @toggle-filters="toggleFilters"
                @order-update="order = $event"
            ></OverviewProductFilterUtils>

            <div class="grid-container">
                <div class="grid-row grid-row--no-wrap">
                    <OverviewProductFilters
                        :brands="brands"
                        :needs="needs"
                        :forms="forms"
                        :orders="orders"
                        :translations="translations"
                        :filter-state="filterState"
                        :available-filters="availableFilters"
                        @filters-update="filters = $event"
                        @filter-state-update="filterState = $event"
                        @order-update="order = $event"
                    ></OverviewProductFilters>
                    <ProductList
                        :products="filteredOrderedProducts"
                        :filter-state="filterState"
                    >
                        <template #featured>
                            <slot name="featured"></slot>
                        </template>
                    </ProductList>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import OverviewProductHeros from '@/app/components/OverviewProductHeros.vue';
    import OverviewProductFilterUtils from '@/app/components/OverviewProductFilterUtils.vue';
    import OverviewProductFilters from '@/app/components/OverviewProductFilters.vue';
    import ProductList from '@/app/components/ProductList.vue';

    export default {
        name: 'OverviewProductList',

        components: { OverviewProductHeros, ProductList, OverviewProductFilterUtils, OverviewProductFilters },

        props: {
            baseHero: { type: Object, default: () => {} },
            brands: { type: Array, default: () => [] },
            needs: { type: Array, default: () => [] },
            forms: { type: Array, default: () => [] },
            hiddenFilters: { type: Array, default: () => [] },
            products: { type: Array, default: () => [], required: true },
            translations: { type: Object, default: () => {}, required: true }
        },

        data: () => ({
            filters: null,
            order: null,
            baseFilter: null,
            filterState: false
        }),

        computed: {
            filteredProducts() {
                // Transform filters to avoid proxy
                const filters = JSON.parse(JSON.stringify(this.filters));

                // Filter the products
                const filteredProducts = this.products.filter((product) => {
                    // Get a flat needs array for product
                    const productFlatNeeds = product.needs.map((need) => { return need.slug});

                    // Try the filters
                    const brand = (filters?.brands?.length > 0) ? filters.brands.includes(product.brand.slug) : true;
                    const form = (filters?.forms?.length > 0) ? filters.forms.includes(product.dosageForm.slug) : true;
                    const needs = (filters?.needs?.length > 0) ? filters.needs.some(r => productFlatNeeds.includes(r)) : true;
                    const hidden = (filters?.hiddenFilter?.length > 0)
                        ? filters.hiddenFilter.some((r) => {
                            return product.hiddenFilters.filter((hf) => { return hf.slug === r }).length > 0
                        })
                        : true;

                    return brand && needs && form && hidden;
                });

                return filteredProducts;
            },

            availableFilters() {
                const productBase = (this.filters && this.filters.hiddenFilter.length > 0) ? this.products : this.filteredProducts;

                // Make an object of filters, return all the filters if from the base filter else make an array from the products selection
                const filt =  {
                    brands: (this.baseFilter === 'brands')
                        ? this.brands.map((brand) => { return brand.slug})
                        : [...new Set(productBase?.map((product) => { return product.brand.slug}))],
                    forms: (this.baseFilter === 'forms')
                        ? this.forms.map((form) => { return form.slug})
                        : [...new Set(productBase?.map((product) => { return product.dosageForm.slug}))],
                    needs: (this.baseFilter === 'needs')
                        ? this.needs.map((need) => { return need.slug})
                        : [...new Set(productBase?.map((product) => {
                        return product.needs.map((need) => { return need.slug })
                    }).flat())]
                };

                return filt;
            },

            filteredOrderedProducts() {
                // Copy products
                let products = JSON.parse(JSON.stringify(this.filteredProducts));

                // On ascending order
                if (this.order && this.order.value === 'ASCENDING') {
                    // Sort the products to get ascending order according to clean price
                    products.sort((a, b) => {
                        return a.cleanPrice - b.cleanPrice
                    });
                } else if (this.order && this.order.value === 'DESCENDING') {
                    // Sort the products to get descending order according to clean price
                    products.sort((a, b) => {
                        return b.cleanPrice - a.cleanPrice
                    });
                }

                return products;
            },

            orders(){
                return {
                    RELEVANCE: {
                        text: this.translations.relevance,
                        value: 'RELEVANCE'
                    },
                    ASCENDING: {
                        text: this.translations.ascendingPrice,
                        value: 'ASCENDING'
                    },
                    DESCENDING: {
                        text: this.translations.descendingPrice,
                        value: 'DESCENDING'
                    }
                }
            },
        },

        watch: {
            'filters.needs'() { this.updateBaseFilter();},
            'filters.brands'() { this.updateBaseFilter();},
            'filters.forms'() { this.updateBaseFilter();}
        },

        methods: {
            // Open/close the filters
            toggleFilters() {
                this.filterState = !this.filterState;
            },

            // Set the first element to be selected
            updateBaseFilter() {
                // if we have only one item selected
                if ([this.filters.brands, this.filters.needs, this.filters.forms].flat().length === 1) {
                    // Check which one it is and set base filter
                    if (this.filters.needs.length > 0) this.baseFilter = 'needs';
                    else if (this.filters.brands.length > 0) this.baseFilter = 'brands';
                    else if (this.filters.forms.length > 0) this.baseFilter = 'forms';

                } else if ([this.filters.brands, this.filters.needs, this.filters.forms].flat().length === 0) {
                    this.baseFilter = null;
                }
            }
        }
    }
</script>

<style lang="scss">
    .overview-product-list {
        &__bg {
            height: calc(100% - 164px);
        }
    }
</style>
