<template>
    <form ref="form" method="POST" @submit.prevent="submitHandler">
        <slot></slot>
        <p v-if="isSuccess" class="text-success mt-2">{{ messages.success }}</p>
        <p v-if="isAlreadySubscribed" class="text-success mt-2">{{ messages.alreadySubscribed }}</p>
        <p v-if="isError" class="text-alert mt-1">{{ messages.error }}</p>
    </form>
</template>

<script>
import axios from "axios";
export default {
    name: "NewsletterForm",

    props: {
        siteUrl: {
            type: String,
            required: true,
        },
        messages: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            isSuccess: false,
            isAlreadySubscribed: false,
            isError: false,
        }
    },

    methods: {
        submitHandler() {
            this.isSuccess = false;
            this.isAlreadySubscribed = false;
            this.isError = false;

            let formData = new FormData(this.$refs.form);

            axios.post(`${this.siteUrl}mailchimp/send`, formData).then((response) => {
                if (response.data.success) {
                    this.isSuccess = true
                    return;
                }

                this.isAlreadySubscribed = true;
            }).catch((error) => {
                console.error(error);
                this.isError = true;
            });
        }
    }
}
</script>
