<template>
    <article class="product-card flex flex-col w-full">
        <div class="product-card__images rounded-lg md:rounded-2xl overflow-hidden relative" :class="`bg-${product.brand?.color?.value}-light`">
            <a v-if="product.image?.url" :href="product.url">
                <img
                    :src="product.image?.url"
                    :alt="product.image?.alt"
                    class="w-full aspect-4/5"
                />

                <img
                    v-if="product.hoverImage"
                    :src="product.hoverImage.url"
                    :alt="product.hoverImage.alt"
                    aria-hidden="true"
                    class="absolute inset-0 w-full h-full object-cover object-center opacity-0 hover:opacity-100 transition-opacity duration-200"
                >
            </a>
        </div>
        <div class="p-4 md:pt-4 md:pb-6 md:px-6">
            <a
                v-if="product.brand?.url && product.brand?.title"
                :href="product.brand?.url"
                :class="`text-${product.brand?.color?.value}`"
                class="brand-label"
            >
                {{ product.brand?.title }}
            </a>
            <a v-if="product.url" :href="product.url" class="block mt-1.5">
                <p class="h5">{{ product.title }}</p>
                <p v-if="product.capacity" class="body-3 mt-1.5">{{ product.capacity }}</p>
                <ul v-if="product.tags.length > 0" class="flex items-start">
                    <li
                        v-for="(tag, index) in product.tags"
                        :key="`tag-${index}`"
                        :class="{'mr-2': index <= product.tags.length - 1}"
                        class="tag mt-3 "
                    >
                        {{ tag }}
                    </li>
                </ul>
                <div v-if="product.cleanPrice && product.cleanPrice > 0" class="flex items-end mt-4">
                    <span v-if="product.price" class="price price--S">{{ product.price }}</span>
                    <p v-if="product.priceMention" class="mention ml-2 mb-0.5">{{ product.priceMention }}</p>
                </div>
            </a>
        </div>
    </article>
</template>

<script>
    export default {
        name: 'ProductCard',

        props: {
            product: { type: Object, default: () => {}, required: true }
        }
    }
</script>
