<template>
    <div class="block-animation">
        <slot></slot>
    </div>
</template>

<script>
    import { gsap } from '@lahautesociete/gsap';
    import { ScrollTrigger } from "@lahautesociete/gsap/ScrollTrigger";
    import { SplitText } from '@lahautesociete/gsap/SplitText';

    gsap.registerPlugin(ScrollTrigger, SplitText);

    export default {
        name: 'BlockAnimation',

        props : {
            type: { type: String, default: null},
            opacity: { type: Number, default: 0},
            ystart: { type: Number, default: null},
            yfinish: { type: Number, default: null},
            xstart: { type: Number, default: null},
            xfinish: { type: Number, default: null},

            delay: { type: Number, default: 0},
            duration: { type: Number, default: 0.4},
            ease: { type: String, default: 'power1.out'},
        },

        data() {
            return {
                breakpoint: 0
            }
        },

        mounted() {
            if(window.innerWidth > this.breakpoint) {
                if(this.opacity) {
                    gsap.set(this.$el, {
                        opacity: 0
                    });
                }

                if(this.ystart) {
                    gsap.set(this.$el, {
                        y: this.ystart
                    });
                }

                if(this.xstart) {
                    gsap.set(this.$el, {
                        x: this.xstart
                    });
                }

                if(this.type === 'textSlide') {
                    if(this.$el.querySelector('p')) {
                        this.split = new SplitText(this.$el.querySelector('p'), { type: 'lines, words', linesClass: 'line', wordsClass: 'word'});
                    } else {
                        this.split = new SplitText(this.$el.querySelector('*'), { type: 'lines, words', linesClass: 'line', wordsClass: 'word'});
                    }

                    gsap.set(this.split.words, {
                        y : 100
                    });
                }

                ScrollTrigger.create({
                    trigger: this.$el,
                    start: "top 60%",
                    end: "bottom bottom",
                    once: true,
                    onEnter: () => {
                        this.startAnimation();
                    }
                });
            }
        },

        methods : {
            startAnimation(){
                if(this.opacity) {
                    gsap.to(this.$el, {
                        opacity: this.opacity,
                        duration: this.duration,
                        delay: this.delay,
                        ease: this.ease
                    });
                }

                if(this.ystart) {
                    gsap.to(this.$el, {
                        y: this.yfinish,
                        duration: this.duration,
                        delay: this.delay,
                        ease: this.ease
                    });
                }

                if(this.xstart) {
                    gsap.to(this.$el, {
                        x: this.xfinish,
                        duration: this.duration,
                        delay: this.delay,
                        ease: this.ease
                    });
                }

                if(this.type === 'textSlide') {
                    Array.prototype.forEach.call(this.split.lines, function(el, i){
                        gsap.to(el.querySelectorAll('.word'),{
                            y: 0,
                            duration : this.duration,
                            delay: this.delay + (i * 0.1),
                            ease: this.ease,
                            onComplete : () => {
                                el.classList.remove('line')
                            }
                        });
                    }.bind(this));
                }
            }
        }
    }
</script>

<style lang="scss">
    .block-animation {
        .line {
            @apply overflow-hidden;
        }
    }
</style>
