<template>
    <div class="tabs">
        <div class="flex items-center">
            <h4
                v-for="(tab, index, key) in tabs"
                :key="key"
                :class="{'ml-6 md:ml-16': index > 0, 'font-semibold special-underline special-underline--up': currentIndex === index}"
                class="cursor-pointer transition-all"
                @click="updateTab(index)"
            >
                {{ tab.heading }}
            </h4>
        </div>
        <div ref="tabContentContainer" class="tabs__content relative mt-6 md:mt-8">
            <div
                v-for="(tab, key) in tabs"
                ref="tabContent"
                :key="key"
                class="body-1 md:w-11/12"
                v-html="tab.text"
            ></div>
        </div>
    </div>
</template>

<script>
    import { gsap } from '@lahautesociete/gsap';

    export default {
        name: 'Tabs',

        props: {
            tabs: { type: Array, default: () => [], required: true }
        },

        data: () => ({
            currentIndex: 0
        }),

        mounted() {
            this.$nextTick(() => {
                this.resizeHandler();
            });

            window.addEventListener('resize', this.resizeHandler);
        },

        methods: {
            updateTab(index) {
                // Hide previous tab content
                gsap.to(this.$refs.tabContent[this.currentIndex], {
                    opacity: 0,
                    display: 'none',
                    duration: 0.3,
                    ease: 'power2.out'
                });

                // Set block to new content to get his height
                gsap.set(this.$refs.tabContent[index], {
                    display: 'block',
                    delay: 0.3,
                    onComplete: () => {
                        // Adapt parent height
                        gsap.to(this.$refs.tabContentContainer, {
                            height: this.$refs.tabContent[index].clientHeight,
                            duration: 0.2,
                            ease: 'power2.out',
                        });

                        // Show next tab content
                        gsap.to(this.$refs.tabContent[index], {
                            opacity: 1,
                            duration: 0.4,
                            ease: 'power2.out'
                        });

                        this.currentIndex = index;

                    }
                });
            },

            resizeHandler() {
                gsap.set(this.$refs.tabContentContainer, {
                    height: this.$refs.tabContent[this.currentIndex].clientHeight
                });
            }
        }
    }
</script>

<style lang="scss">
    .tabs {
        &__content > div:not(:first-child){
            @apply hidden opacity-0;
        }
    }
</style>
