<template>
    <div id="trustbeauty_ambassador_thread"></div>
</template>

<script>
export default {
    name: "BecomeAmbassador",

    mounted() {
        const d = document, s = d.createElement('script');
        s.src = 'https://app.trustt.io/static/js/embed/trustbeauty-ambassador.min.js';
        s.setAttribute('data-timestamp', +new Date());
        (d.head || d.body).appendChild(s);
    },
}
</script>
