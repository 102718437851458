<template>
    <div v-if="hero" class="hero-simple">
        <div class="grid-container">
            <div class="grid-row">
                <div class="grid-col w-full">
                    <div class="hero-simple__content rounded-lg md:rounded-2xl overflow-hidden relative">
                        <!-- IMAGE -->
                        <img
                            v-if="hero.image?.url"
                            :src="hero.image.url"
                            :alt="hero.image?.alt"
                            class="object-cover w-full h-full"
                        />

                        <div class="overlay--grey-11-40 absolute inset-0"></div>

                        <h1 v-if="hero.heading" class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white">
                            <span class="special-underline special-underline--down-up special-underline--skin after:w-28 text-center">{{ hero.heading }}</span>
                        </h1>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'HeroSimple',

        props: {
            hero: { type: Object, default: () => {} }
        }
    }
</script>
